import React from "react"
import logo from "../../assets/images/bolttech_logo_footer.svg"

const Footer = function () {
  return (
    <>
      <div className="main-footer">
        <div className="container">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>
          <div className="linksWrapper">
            <a href="/tos" target="_blank">
            Nutzungsbedinungen
            </a>
            <a href="/disclaimer" target="_blank">
            Impressum
            </a>
          </div>
          <div className="copyrights">
            <div className="row space-between">
              <div className="col-md-7 copyright">
                © bolttech Digital Brokerage (EU) SARL. All rights reserved.
              </div>
              <div className='connection'>
                The power of <span>connection</span>.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Footer
