import React, {useState, useEffect} from 'react';
import { navigate } from 'gatsby';
import logo from "../../assets/images/bolttech_logo_header_de.svg";
import { useQueryParam, StringParam } from "use-query-params";

const Header = () => {

  const langs = [{id: 'EN', value: 'EN'}, {id: 'BM', value: 'BM'}]
    const [filteredLangs, setFilteredLangs] = useState([]);
    const [defaultLang, setDefaultLang] = useState('BM');
    const [queryLang, ] = useQueryParam("lang", StringParam);
    
    useEffect(() => {
      const defaultLang = (typeof window !== 'undefined') && (queryLang !== undefined ? queryLang : window.localStorage.getItem('language_hsbc') ? window.localStorage.getItem('language_hsbc') : 'BM')
      const filtLangs = langs.filter(
          (lang) => lang.id !== defaultLang
      );
      setFilteredLangs(filtLangs)
      setDefaultLang(defaultLang)
    }, [])

    const handleChangeLang = (lang) => {
      console.log('lang: ', lang)
      const filtLangs = langs.filter((item) => lang !== item.id);
      setFilteredLangs(filtLangs)
      let newLang = defaultLang
      if (newLang === 'BM') {
          newLang = langs[1].value
      }
      setDefaultLang(defaultLang)
      window.localStorage.setItem('language_hsbc', lang);
      window.location.reload();
    }

  return (
    <>
      <div className="header">
        <div className="container">
          <div onClick={() => navigate('/')} className="logo">
            <img src={logo} alt="bolttech" />  <p className='insurance'><span className='fw-300'>|</span> <span className='ml-3 '>Insurance</span></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;