import React from "react"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import LoadingSpinner from "../shared/loadingspinner"
import Header from "../shared/header";
import Footer from "../shared/footer";
import "../../assets/css/stylesheet.scss";
import PropTypes from "prop-types";
import favico from '../../assets/images/favicon.ico';


let dataLayer;
if (typeof window !== 'undefined') {
  dataLayer = window.dataLayer || [];
}


const Layout = ({ header, footer, isBackBtn, prevUrl, children }) => {
  useEffect(() => {
    dataLayer.push({ event: "pageView" })
  })
  const isHeader = () => {
    if (header) {
      return "pt-80"
    } else {
      return ""
    }
  }
  return (
    <>
      <Helmet>
        <title>Service portal</title>
        <link rel="shortcut icon" href={favico} type="image/x-icon" />
      </Helmet>
      {/* <LoadingSpinner isVisible={loaderStore.isVisible}></LoadingSpinner> */}
      {header && <Header isBackBtn={isBackBtn} prevUrl={prevUrl} />}
      <main className={isHeader()}>{children}</main>
      {footer && <Footer />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
